w.benefits = {
  oConf: {},

  init: function () {
    this.vSlider();
    this.scrollTo();
  },

  vSlider: function () {
    var vSlider = $('.vSlider').lightSlider({
      item: 1,
      loop: false,
      slideMove: 1,
      slideMargin: 0,
      mode: 'fade',
      speed: 1000,
      slideEndAnimation: false,
      controls: false,
      pager: false,
      gallery: false,
      enableTouch: false,
      enableDrag: false,
      freeMove: false,
      onSliderLoad: function() {
        $('.vSlider').removeClass('cS-hidden');
      }
    });

    /*var urlFunWave = 'http://wow.test/wp-content/themes/wow/public/video/video_fun_waves.mp4'
    var urlLife = 'http://wow.test/wp-content/themes/wow/public/video/video_life.mp4'
    var urlTraining = 'http://wow.test/wp-content/themes/wow/public/video/video_training.mp4'*/

    var urlBenefits = 'https://knok.be/wow/wp-content/themes/wow/public/video/video_fun_waves.mp4'

    $('#video4').vide(urlBenefits, {autoplay: true});

  },

  scrollTo: function () {
    $(document).on('click', 'a[href^="#"]', function (event) {
      event.preventDefault();

      $('html, body').animate({
        scrollTop: $($.attr(this, 'href')).offset().top
      }, 500);
    });
  }

}
