w.home = {
  oConf: {},

  init: function () {
    w.animTitle.init();
    this.cardT();
    this.hTech();
    this.hWaveBall();
    this.hApply();
    //this.scrollTo();
    this.hideJs();
    this.hideBox();
  },

  hideBox: function () {
    $(document).ready(function(){
      $("#hide").click(function(){
        $("#scroll").hide();
      });
    });
  },

  hideJs: function () {
    $(".hideJs").css("visibility", "visible");
  },

  cardT: function () {
    window.sr = ScrollReveal();
    sr.reveal(
      ".cardT",
      {
        origin: "left",
        distance: "40px",
        duration: 700,
        opacity: 0,
        viewFactor: 0.35,
      },
      150
    );
  },

  hWaveBall: function () {
    window.sr = ScrollReveal();
    sr.reveal(
      ".hWaveBall__card",
      {
        origin: "left",
        distance: "40px",
        duration: 700,
        opacity: 0,
        viewFactor: 0.35,
      },
      150
    );
  },

  hTech: function () {
    window.sr = ScrollReveal();

    sr.reveal(
      ".hTech__text p",
      {
        origin: "top",
        distance: "20px",
        duration: 700,
        opacity: 0,
        viewFactor: 0.1,
      },
      100
    );

    sr.reveal(
      ".hTech__ripples__img",
      {
        origin: "bottom",
        distance: "80px",
        duration: 1000,
        opacity: 0,
        viewFactor: 0.2,
      },
      50
    );
  },

  hApply: function () {
    window.sr = ScrollReveal();

    sr.reveal(
      ".hApply__img",
      {
        origin: "right",
        distance: "40px",
        duration: 1000,
        opacity: 0,
        viewFactor: 0.35,
      },
      150
    );

    sr.reveal(
      ".hApply__card__title",
      {
        origin: "left",
        distance: "20px",
        duration: 600,
        opacity: 0,
        viewFactor: 0.35,
      },
      150
    );
  },

  scrollTo: function () {
    // Add smooth scrolling to all links
    $('a[href*="#"]')
      // Remove links that don't actually link to anything
      .not('[href="#"]')
      .not('[href="#0"]')
      .click(function (event) {
        // On-page links
        if (
          location.pathname.replace(/^\//, "") ==
            this.pathname.replace(/^\//, "") &&
          location.hostname == this.hostname
        ) {
          // Figure out element to scroll to
          var target = $(this.hash);
          target = target.length
            ? target
            : $("[name=" + this.hash.slice(1) + "]");
          // Does a scroll target exist?
          if (target.length) {
            // Only prevent default if animation is actually gonna happen
            event.preventDefault();
            $("html, body").animate(
              {
                scrollTop: target.offset().top,
              },
              1000,
              function () {
                // Callback after animation
                // Must change focus!
                var $target = $(target);
                $target.focus();
                if ($target.is(":focus")) {
                  // Checking if the target was focused
                  return false;
                } else {
                  $target.attr("tabindex", "-1"); // Adding tabindex for elements not focusable
                  $target.focus(); // Set focus again
                }
              }
            );
          }
        }
      });
  },
};
