var w = {
  // global vars
  sCurrent: null,
  isLoaded: false,

  // global elements
  $body: null,

  // Navigation responsive

  // pages
  home: {},

  // setup
  init: function () {
    w.getElements();
    w.sCurrent = w.getCurrentPage();
    w.loadPage();
    w.isLoaded = true;
    w.menu.init();
  },
  loadPage: function () {
    if (w.sCurrent) w[w.sCurrent].init();
  },

  // functions
  getElements: function () {
    w.$body = $('body');
  },

  getCurrentPage: function () {
    if (w.$body.data('page') == 'home' ) return 'home';
    if (w.$body.data('page') == 'funwaves' ) return 'funwaves';
    if (w.$body.data('page') == 'benefits' ) return 'benefits';
    if (w.$body.data('page') == 'products' ) return 'products';
    if (w.$body.data('page') == 'refit' ) return 'refit';
    if (w.$body.data('page') == 'standard' ) return 'standard';
    if (w.$body.data('page') == 'technologie' ) return 'technologie';
    if (w.$body.data('page') == 'tailorfit' ) return 'tailorfit';
    if (w.$body.data('page') == 'contact' ) return 'contact';
    if (w.$body.data('page') == 'about' ) return 'about';

    return false;
  }
};

$(window).on('load', w.init);
