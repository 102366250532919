w.about = {
  oConf: {},

  init: function () {
    this.vSlider();
  },

  vSlider: function () {
    var vSlider = $('.vSlider').lightSlider({
      item: 1,
      loop: false,
      slideMove: 1,
      slideMargin: 0,
      mode: 'fade',
      speed: 1000,
      slideEndAnimation: false,
      controls: false,
      pager: false,
      gallery: false,
      enableTouch: false,
      enableDrag: false,
      freeMove: false,
      onSliderLoad: function() {
        $('.vSlider').removeClass('cS-hidden');
      }
    });

  },

}
