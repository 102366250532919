w.tailorfit = {
  oConf: {},

  init: function () {
    this.vSlider();
    this.hideImg();
  },

  vSlider: function () {
    var vSlider = $('.vSlider').lightSlider({
      item: 1,
      loop: false,
      slideMove: 1,
      slideMargin: 0,
      mode: 'fade',
      speed: 1000,
      slideEndAnimation: false,
      controls: false,
      pager: false,
      gallery: false,
      enableTouch: false,
      enableDrag: false,
      freeMove: false,
      onSliderLoad: function() {
        $('.vSlider').removeClass('cS-hidden');
      }
    });

    /*var urlFunWave = 'http://wow.test/wp-content/themes/wow/public/video/video_fun_waves.mp4'
    var urlLife = 'http://wow.test/wp-content/themes/wow/public/video/video_life.mp4'
    var urlTraining = 'http://wow.test/wp-content/themes/wow/public/video/video_training.mp4'*/

    var urlStandard = 'https://knok.be/wow/wp-content/themes/wow/public/video/video_fun_waves.mp4'

    $('#video9').vide(urlStandard, {autoplay: true});

  },

  hideImg: function () {
    $('.tailorEx__wrapper').hide();

    $('.tech__title').click(function () {
      $(this).next('.tailorEx__wrapper').toggle();
      $(this).toggleClass('open');
    })
  }

}
