w.menu = {

  init: function () {
    this.openMenu();
    this.openTrigger();
  },

  openMenu: function () {
    var button = $('#hamburger'),
        buttonExit = $('#hamburger-close'),
        body = $('body');

    var menuItemsIn = anime({
      targets: '.menu__item',
      translateX: 150,
      direction: 'normal',
      opacity: 1,
      duration: 1500,
      elasticity: 0,
      delay: function(el, i, l) {
        return i * 200;
      },
      autoplay: false,
    });


    button.click(function () {
      $(this).toggleClass('is-active');
      body.toggleClass('menu-open');
      body.toggleClass('menu-close');

      setTimeout( function () {
        menuItemsIn.restart();
      }, 350)
    });

    buttonExit.click(function () {
      body.removeClass('menu-open');
      body.addClass('menu-close');
      button.toggleClass('is-active');

    });




    $(document).keyup(function (e) {
      if (e.keyCode == 27) {
        body.removeClass('menu-open');
        button.removeClass('is-active');
        body.addClass('menu-close');
      }
    })
  },

  openTrigger: function () {
    var menu = $('.menu'),
        triggerButton = $('.menu__item--trigger');

    $('.menu__link-not').click(function (e) {
      e.preventDefault();
      menu.toggleClass('menu--trigger-open');


      var ul = $('.subnav').find('ul.subnav__list').height();

      if (menu.hasClass('menu--trigger-open')){
        menu.find('div.subnav').css('height', ul);
      } else {
        menu.find('div.subnav').css('height', 0);
      }

    });

  },

}
