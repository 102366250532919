w.technologie = {
  oConf: {},

  init: function () {
    this.vSlider();
    this.hideImg();
    this.initTabs(); // Appel à la méthode d'initialisation des tabs
    this.initFirstSlider(); // Correction : Initier uniquement le premier slider
    this.initSecondSlider(); // Initier le second slider
    this.initModal();
  },

  vSlider: function () {
    var vSlider = $('.vSlider').lightSlider({
      item: 1,
      loop: false,
      slideMove: 1,
      slideMargin: 0,
      mode: 'fade',
      speed: 1000,
      slideEndAnimation: false,
      controls: false,
      pager: false,
      gallery: false,
      enableTouch: false,
      enableDrag: false,
      freeMove: false,
      onSliderLoad: function() {
        $('.vSlider').removeClass('cS-hidden');
      }
    });

    var urlStandard = 'https://knok.be/wow/wp-content/themes/wow/public/video/video_fun_waves.mp4';
    $('#video8').vide(urlStandard, {autoplay: true});
  },

  hideImg: function () {
    $('.techTabs__wrapper').hide();

    $('.tech__title').click(function () {
      $(this).next('.techTabs__wrapper').toggle();
      $(this).toggleClass('open');
    });
  },

  initTabs: function () {
    // Par défaut, on cache tout sauf la première tab
    $('.tab-content .tab').hide(); // Cache tous les contenus des tabs
    $('.tab-content .tab:first').show(); // Affiche seulement le premier contenu

    // Ajouter la classe active au premier lien et au premier contenu
    $('.tabs-links li:first').addClass('active');
    $('.tabs-links a:first').addClass('active'); // Assurez-vous que le premier lien est aussi marqué comme actif

    // Gestion des tabs
    $('.tabs-links a').on('click', function (e) {
      e.preventDefault(); // Empêche le comportement par défaut du lien

      // Enlever la classe active de tous les liens et contenus
      $('.tabs-links li').removeClass('active');
      $('.tab-content .tab').hide();

      // Ajouter la classe active au lien et au contenu cliqué
      $(this).parent('li').addClass('active');
      var currentTab = $(this).attr('href');
      $(currentTab).show(); // Afficher le contenu du tab correspondant
    });
  },

  initFirstSlider: function () {
    var firstSlider = new Swiper('.first-slider', {
      loop: true,
      slidesPerView: 1, // 3 slides visibles à la fois
      spaceBetween: 30, // Espace entre chaque slide
      navigation: {
        nextEl: '.first-slider .swiper-button-next',
        prevEl: '.first-slider .swiper-button-prev',
      },
      pagination: {
        el: '.first-slider .swiper-pagination',
        clickable: true,
      },
      autoplay: false, // Désactive l'autoplay
      breakpoints: {
        768: {
          slidesPerView: 2, // Tablette : 2 slides visibles
          spaceBetween: 30, // Un peu plus d'espace
        },
        1024: {
          slidesPerView: 3, // Desktop : 3 slides visibles
          spaceBetween: 50, // Plus d'espace
        },
      },
    });
  },

  initSecondSlider: function () {
    var secondSlider = new Swiper('.second-slider', {
      loop: true,
      slidesPerView: 1, // Un seul slide visible à la fois
      autoplay: {
        delay: 3000, // Changement toutes les 3 secondes
        disableOnInteraction: false, // Continue même après interaction
      },
      pagination: {
        el: '.second-slider .swiper-pagination',
        clickable: true,
      },
    });
  },

  initModal: function () {
    // Ouvrir la modal
    $('.open-modal').on('click', function() {
      var targetModal = $(this).data('target');
      $(targetModal).css('display', 'flex'); // Utilisation de CSS flex au lieu de display: block
    });

    // Fermer la modal
    $('.modal-close').on('click', function() {
      $(this).closest('.modal').css('display', 'none'); // Fermer la modal
    });

    // Fermer la modal en cliquant en dehors de la boîte de contenu
    $(document).on('click', function(event) {
      if ($(event.target).hasClass('modal')) {
        $(event.target).css('display', 'none');
      }
    });
  },
};
