w.funwaves = {
  oConf: {},

  init: function () {
    this.vSlider();
    this.sliderPics()
    this.tabs();
    this.lightGallery();
    this.sliderPartners();
    this.moreTech();
  },

  vSlider: function () {
    var vSlider = $('.vSlider').lightSlider({
      item: 1,
      loop: false,
      slideMove: 1,
      slideMargin: 0,
      mode: 'fade',
      speed: 1000,
      slideEndAnimation: false,
      controls: false,
      pager: false,
      gallery: false,
      enableTouch: false,
      enableDrag: false,
      freeMove: false,
      onSliderLoad: function() {
        $('.vSlider').removeClass('cS-hidden');
      }
    });

    /*var urlFunWave = 'http://wow.test/wp-content/themes/wow/public/video/video_fun_waves.mp4'
    var urlLife = 'http://wow.test/wp-content/themes/wow/public/video/video_life.mp4'
    var urlTraining = 'http://wow.test/wp-content/themes/wow/public/video/video_training.mp4'*/

    var urlFunWave = 'https://wowcompany.com/wp-content/themes/wow/public/video/WOW_FUN WAVES_2BD.mp4'
    var urlLife = 'https://wowcompany.com/wp-content/themes/wow/public/video/WOW_LIFE SCIENCES_BD.mp4'
    var urlTraining = 'https://wowcompany.com/wp-content/themes/wow/public/video/WOW_TRAINING_2BD.mp4'

    $('#video1').vide(urlFunWave, {autoplay: true});
    $('#video2').vide(urlLife, {autoplay: true});
    $('#video3').vide(urlTraining, {autoplay: true});


    /*$('#video2').vide( url, { autoplay: false });
    $('#video3').vide( url, { autoplay: false });
    $('#video4').vide( url, { autoplay: false });

    var slide1 = $("#video1").data("vide").getVideoObject();
    var slide2 = $("#video2").data("vide").getVideoObject();
    var slide3 = $("#video3").data("vide").getVideoObject();
    var slide4 = $("#video4").data("vide").getVideoObject();

    var button1 = $('#goToSlide1');
    var button2 = $('#goToSlide2');
    var button3 = $('#goToSlide3');
    var button4 = $('#goToSlide4');

    var allButtons = $('.navSlider__button');

    slide2.pause();
    slide3.pause();
    slide4.pause();

    $('#slider1play').on('click', function() { slide1.paused ? slide1.play() : slide1.pause(); });
    $('#slider2play').on('click', function() { slide2.paused ? slide2.play() : slide2.pause(); });

    button1.click(function () {
      vSlider.goToSlide(0);
      slide1.play(); slide2.pause(); slide3.pause(); slide4.pause();
      allButtons.removeClass('active');
      $(this).addClass('active');
    });

    button2.click(function () {
      vSlider.goToSlide(1);
      slide1.pause(); slide3.pause(); slide4.pause(); slide2.play();
      allButtons.removeClass('active');
      $(this).addClass('active');
    });

    button3.click(function () {
      vSlider.goToSlide(2);
      slide1.pause(); slide2.pause(); slide4.pause(); slide3.play();
      allButtons.removeClass('active');
      $(this).addClass('active');
    });

    button4.click(function () {
      vSlider.goToSlide(3);
      slide1.pause(); slide2.pause(); slide3.pause(); slide4.play();
      allButtons.removeClass('active');
      $(this).addClass('active');
    });*/

  },


  sliderPics: function () {
    $('.sliderPics1').lightSlider({
      item: 1,
      loop: true,
      easing: 'cubic-bezier(0.25, 0, 0.25, 1)',
      speed: 600,
      slideMargin: 0,
      auto: true,
      pauseOnHover: true,
      pause: 3000,
      onSliderLoad: function (el) {
        el.lightGallery({
          selector: '.sliderPics1 .lslide',
          thumbnail: false
        })
      }
    });

    setTimeout(function () {
      $('.sliderPics2').lightSlider({
        item: 1,
        loop: true,
        easing: 'cubic-bezier(0.25, 0, 0.25, 1)',
        speed: 600,
        slideMargin: 0,
        auto: true,
        pauseOnHover: true,
        pause: 3000,
        onSliderLoad: function (el) {
          el.lightGallery({
            selector: '.sliderPics2 .lslide',
            thumbnail: false
          })
        }
      });
    }, 1000)
  },

  tabs: function () {
    $('#tabs').tabs();
  },

  lightGallery: function () {
    $('.lightGallery').lightGallery({
      thumbnail: false
    });
  },

  sliderPartners: function () {
    $('.partners__slider').lightSlider({
      item: 5,
      slideMargin: 10,
      controls: false,
      slideMove: 5,
      auto: true,
      pauseOnHover: true,
      pause: 3500,
      responsive : [
        {
          breakpoint:768,
          settings: {
            item:3,
            slideMove:3,
          }
        },
        {
          breakpoint:425,
          settings: {
            item:2,
            slideMove:2,
          }
        }
      ]
  });
  },

  moreTech: function () {
    var button = $('#butOpTech');

    button.click(function () {
      $('#opTech').toggleClass('hidden');
    });

    $.fn.extend({
      toggleText: function (a, b) {
        if (this.html() == a) {
          this.html(b)
        }
        else {
          this.html(a)
        }
      }
    });
  }

}
