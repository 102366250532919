w.animTitle = {

  init: function () {
    this.animLine1Header();
    //this.animeLine2Header();
  },

  animLine1Header: function () {
    // Wrap every letter in a span
    $('#line1').each(function(){
      $(this).html($(this).text().replace(/([^\x00-\x80]|\w)/g, "<span class='letter'>$&</span>"));
    });
    $('#line2').each(function(){
      $(this).html($(this).text().replace(/([^\x00-\x80]|\w)/g, "<span class='letter'>$&</span>"));
    });
    $('#line3').each(function(){
      $(this).html($(this).text().replace(/([^\x00-\x80]|\w)/g, "<span class='letter'>$&</span>"));
    });

    anime.timeline({loop: false})
        .add({
          targets: '#line1 .letter',
          opacity: [0,1],
          easing: "easeInOutQuad",
          duration: 600,
          delay: function(el, i) {
            return 150 * (i+1)
          }
        }).add({
          targets: '#line2 .letter',
          translateY: [-100,0],
          easing: "easeOutExpo",
          duration: 800,
          delay: function(el, i) {
            return 30 * i;
        }
    }).add({
      targets: '#line3 .letter',
      translateY: [-100,0],
      easing: "easeOutExpo",
      duration: 800,
      delay: function(el, i) {
        return 30 * i;
      }
    })
  },

}
